import React from "react";
import mHeading from "../images/snd.png";
import Wobble from "react-reveal/Wobble";
import Zoom from "react-reveal/Zoom";
import HeadShake from "react-reveal/HeadShake";
import gloryBgVideo from "../video/snc.mp4";
import Jello from "react-reveal/Jello";
import Shake from "react-reveal/Shake";
import Bounce from "react-reveal/Bounce";

const HeadSection = () => {
  return (
    <div>
      {" "}
      <div id="home">
        <div className="sections">
          <div className="firstLayer ">
            <Shake>
              <div
                className="mHead"
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <Jello>
                  <img src={mHeading} className="mainimg p-5" alt="..." />
                </Jello>
              </div>
            </Shake>

            <>
              <div className="text-center  pt-5  activi">
                <h4 className="">
        
                  <strong className="main_text  neon ">
                    <span> ACTIVATE YOUR WEB3 VISION </span>
                  </strong>
                </h4>
               
              
              </div>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default HeadSection;
