import React from "react";
import { Grid } from "@mui/material";
import Bounce from "react-reveal/Bounce";
import eth from "../images/gip.gif";
import "./st.scss";
const Aboutus = () => {
  return (
    <div id="about" style={{ backgroundAttachment: "fixed" }}>
      <div className="sections">
        <div className="firstLayer ">
          <>
            <div className="text-center  pt-5  activi">
              <h1
                style={{
                  color: "#fff",
                  borderRadius: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "600",
                }}
              >
                {" "}
                About Us&nbsp;
              </h1>

              <div className="mb_set" style={{ padding: "80px" }}>
                <Bounce left>
                  <h3>
                    <em>
                      <span className="other_text">
                        SNDC8 is a blockchain & NFT native agency helping
                        traditional businesses succeed in the endeavor to
                        incorporate web3 strategies by leveraging cutting edge
                        technology to bring innovation, untapped revenue
                        streams, and unsurpassed community & brand
                        building opportunities for legacy institutions.

                      </span>
                    </em>
                  </h3>
                </Bounce>

                <br />
                <br />
                <Bounce right>
                  {" "}
                  <h3>
                    <em>
                      <span className="other_text">
                        We assist web3 startups with navigating complicated
                        concepts while collaborating & networking with
                        industry experts who utilize the web3 technology stack
                        to deliver innovative solutions.

                      </span>
                    </em>
                  </h3>
                </Bounce>

                <br />
                <br />
                <Bounce left>
                  {" "}
                  <h3>
                    <em>
                      <span className="other_text">
                        We leverage the skills, knowledge, and passion of our
                        team of web3 experts to positively impact the space &
                        assist those looking to contribute to web3.

                      </span>
                    </em>
                  </h3>
                </Bounce>

                <br />
                <br />
                <Bounce left>
                  <h3>
                    <em>
                      <span className="other_text">
                        We are a core group of web3 enthusiasts, builders,
                        professionals, contributors, and creatives. Although
                        broad in our expertise, the central driving force for
                        everything we do is to celebrate, elevate, and innovate
                        in the web3 space.

                      </span>
                    </em>
                  </h3>
                </Bounce>

                <br />
                <br />
                <Bounce right>
                  {" "}
                  <h3>
                    <em>
                      <span className="other_text">
                        Put simply, SNDC8 strives to contribute to the
                        innovation, enhancement, and refinement of web3, as we
                        believe web3 technology will benefit billions of people
                        around the globe.

                      </span>
                    </em>
                  </h3>
                </Bounce>

                <br />
                <br />
              </div>
            </div>
          </>
        </div>
      </div>

      <br />
    </div>
  );
};

export default Aboutus;
