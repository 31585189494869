import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

const Footer = () => {
  return (
    <MDBFooter
      style={{ backgroundColor: "#4343" }}
      className="text-center text-lg-start text-muted"
    >
      <br />
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="4" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{
                  color: " #FFF",
                  fontWeight: "600",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                SNDC8 Web3 | Agency
              </h6>
              <p
                style={{
                  color: " #FFF",
                  fontWeight: "600",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Copyright © 2022 | All rights reserved.
              </p>
            </MDBCol>

            <MDBCol md="3" lg="4" xl="4" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{
                  color: " #FFF",
                  fontWeight: "600",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Designed By{" "}
              </h6>
              <p style={{ justifyContent: "center", textAlign: "center" }}>
                <a
                  href="https://linktr.ee/noormuhammad_uc"
                
                  target="_blank"
                  style={{
                    color: " #FFF",
                    fontWeight: "600",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  Noor Muhammad
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="4" xl="4" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{
                  color: " #FFF",
                  fontWeight: "600",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                Contact Us
              </h6>

              <a
                href="https://xz5xq3sln08.typeform.com/to/hNpg1z1l"
                style={{
                  textDecoration: "none",

                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                target="_blank"
              >
                <button
                  type="button"
                  className="blocks buttons"
                  style={{
                    color: " #FFF",
                    fontWeight: "600",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  Submit Inquiry
                </button>
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
};

export default Footer;
