import React from "react";
import gloryBgVideo from "../video/snc.mp4";
import "./st.scss";
const HeadSection = () => {
  return (
    <div>
      {" "}
      <div id="home">
        <div
          style={{
            top: "0",
            left: "0",
            right: "0",
          }}
        >
          <video className="videoTag" loop="true" autoplay="autoplay" muted>
            <source src={gloryBgVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
