import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import Bounce from "react-reveal/Bounce";
import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper";

const TDSldier = () => {
  return (
    <div id="services" className="service_bg">
      <br />
      <br />
      <Bounce top>
        <h1
          style={{
            color: "#fff",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            fontWeight: "600",
          }}
          className="h1"
          contenteditable
          data-heading="Frozen"
        >
          {" "}
          Services&nbsp;
          <br />
          <br />
          <br />
        </h1>
      </Bounce>
      <br />
      <br />
      <br />

      <div className="container">
        {" "}
        <Bounce>
          <Bounce right>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              autoplay={{
                delay: 12000,
                disableOnInteraction: true,
              }}
              pagination={true}
              modules={[EffectCoverflow, Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div class="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div class="content">
                    <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                      {" "}
                      Web3 Consulting{" "}
                    </h2>
                    <br />
                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                      <a>
                        Our team is prepared to assist you in joining the web3
                        revolution, whether your brand requires assistance with
                        building NFT collections, carrying out a marketing
                        strategy for your NFT mint, or wishing to strategically 
                        position your brand for success in an enhanced web3 world.
                      </a>
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div class="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div class="content">
                    <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                      {" "}
                      Web3 Strategy{" "}
                    </h2>
                    <br />
                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                      <a>
                        Assist your brand with sorting out the ramifications
                        of web3 and creating a future-back perspective of how it
                        might affect your market. Evaluate potential
                        possibilities and challenges, redesign your brand’s
                        model to incorporate fresh digital experiences, assist
                        with product launches, and plot a route for generating
                        value over the long-term.
                      </a>
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div class="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div class="content">
                    <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                      {" "}
                      Artwork & Metadata{" "}
                    </h2>
                    <br />
                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                      <a>
                        Expertise in arranging NFT artwork layers, organizing
                        traits & attributes, creating distinctive & unique metadata,
                        hosting data on IPFS, engineering final NFT builds, and 
                        ensuring your brand has the breadth & depth of storytelling
                        necessary to compete with "blue chip" brands.
                      </a>
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div class="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div class="content">
                    <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                      {" "}
                      Community Building{" "}
                    </h2>
                    <br />
                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                      <a>
                        Expertise in building & managing communities so your
                        brand can implement innovative & creative strategies to
                        increase virtual experiences between brand & consumer.
                        Our goal is to ensure your brand provides value to your
                        community so your community can reciprocate that value
                        back to your brand.
                      </a>
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div class="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div class="content">
                    <h2 style={{ fontSize: "24px", textAlign: "center" }}>
                      {" "}
                      Network Effect{" "}
                    </h2>
                    <br />
                    <p style={{ textAlign: "center", fontSize: "12px" }}>
                      <a>
                        Our experts possess deep roots in many high-quality
                        token-gated web3 communities such as Midnight Labs,
                        Wumbo Labs, and Proof Collective to assist with
                        delivering cutting-edge technology & skills to your
                        brand while leveraging our knowledge across all elements
                        of web3.
                      </a>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </Bounce>
        </Bounce>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default TDSldier;
